import React from "react";
import styles from "./header.module.scss";
import { Link } from "gatsby";
import { Location } from "@reach/router";
import ListIcon from "@material-ui/icons/List";
import WebshopContext from "../../providers/WebshopProvider";
import cartImage from "../../../static/images/temp/shopping_cart-32px.png";

import buyerGroup from "../../temp/buyerGroup.json";
export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
    };
  }

  toggleMenu = () => {
    this.setState({ showMenu: !this.state.showMenu });
  };

  getWebshopCheckoutUrl = () => {
    return `/co/cart/g/${this.props.buyerGroupId}`;
  };

  render() {
    return (
      <WebshopContext.Consumer>
        {({ categories }) => (
          <Location>
            {locationProps => (
              <div
                className={[
                  styles.headerWrapper,
                  "backgroundPrimaryColor",
                ].join(" ")}
              >
                <nav className={styles.mobileMenu}>
                  <div className={styles.secondSection}>
                    <Link
                      to={
                        locationProps.location.pathname
                          .replace("/s", "")
                          .split("altmetall-ankauf")[0] + "altmetall-ankauf"
                      }
                    >
                      <img
                        src={this.props.webshopLogo}
                        alt="logo"
                        className={[styles.companyLogoImg].join(" ")}
                      />
                    </Link>
                  </div>
                  <div className={styles.cartWrapper}>
                    <a
                      href={this.getWebshopCheckoutUrl()}
                      className={[styles.coLink, "colorBasedOnPrimary"].join(
                        " "
                      )}
                    >
                      <img
                        alt="cart"
                        src={cartImage}
                        className={styles.headerCart}
                      />
                    </a>
                  </div>
                </nav>

                {this.state.showMenu ? (
                  <div
                    className={styles.mobileMenuGroup}
                    onClick={this.toggleMenu}
                  >
                    <div className={styles.mobileMenuWrapper}>
                      <span className={styles.menuTitle}>MENÜ</span>
                      <div className={styles.listOfCategoriesWrapper}>
                        <ListIcon />
                        <div className={styles.listOfCategories}>
                          <span>Altmetall Kategorien</span>
                          <ul>
                            {categories.length
                              ? categories
                                  .filter(val => val.parent === 0)
                                  .map((value, index) => {
                                    return (
                                      <li>
                                        <Link
                                          to={
                                            locationProps.location.pathname
                                              .replace("/s", "")
                                              .split("/altmetall-ankauf")[0] +
                                            "/altmetall-ankauf/" +
                                            value.slug
                                          }
                                        >
                                          {value.name}
                                        </Link>
                                      </li>
                                    );
                                  })
                              : null}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                <nav className={styles.desktopMenu}>
                  <div className={styles.companyInfoWrapper}>
                    <Link
                      to={
                        locationProps.location.pathname
                          .replace("/s", "")
                          .split("altmetall-ankauf")[0] + "altmetall-ankauf"
                      }
                    >
                      <img
                        src={this.props.webshopLogo}
                        alt="logo"
                        className={[styles.companyLogoImg].join(" ")}
                      />
                    </Link>
                    <Link
                      to={
                        locationProps.location.pathname
                          .replace("/s", "")
                          .split("altmetall-ankauf")[0] + "altmetall-ankauf"
                      }
                      className={[
                        styles.altmetallLink,
                        "colorBasedOnPrimary",
                      ].join(" ")}
                    >
                      {buyerGroup.nameShown && buyerGroup.webshopName}
                    </Link>
                  </div>
                  <div className={styles.cartWrapper}>
                    <a
                      href={this.getWebshopCheckoutUrl()}
                      className={[styles.coLink, "colorBasedOnPrimary"].join(
                        " "
                      )}
                    >
                      <img
                        alt="cart"
                        src={cartImage}
                        className={styles.headerCart}
                      />
                      <span>Verkaufskorb</span>
                    </a>
                  </div>
                </nav>
              </div>
            )}
          </Location>
        )}
      </WebshopContext.Consumer>
    );
  }
}
