import React from "react";
import styles from "./footer.module.scss";
import ReactHtmlParser from "react-html-parser";

export default class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
    };
  }

  render() {
    return (
      <div
        className={[
          styles.footer,
          "backgroundPrimaryColor",
          styles[this.props.webshopAddress],
        ].join(" ")}
      >
        <div className={styles.footerWrapper}>
          <div className={styles.termsAndConditionsWrapper}>
            <div>
              <a
                className={[styles.homeLink, "colorBasedOnPrimary"].join(" ")}
                href={this.props.logoUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {this.props.webshopfooterName}
              </a>
              <div className={styles.addressContainer}>
                <span>{this.props.webshopAddress}</span>

                <span>
                  {this.props.webshopZipCode} {this.props.webshopCity}
                </span>
              </div>
              <div className={styles.openingHoursContainer}>
                {ReactHtmlParser(this.props.webshopOpeningHours)}
              </div>
            </div>

            <div className={styles.faqContainer}>
              {this.props.webshopTermsLink ? (
                <a
                  className={[
                    styles.termsAndConditionsLink,
                    "colorBasedOnPrimary",
                  ].join(" ")}
                  href={this.props.webshopTermsLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  AGB
                </a>
              ) : null}

              {this.props.webshopPrivacyLink ? (
                <a
                  className={[
                    styles.termsAndConditionsLink,
                    "colorBasedOnPrimary",
                  ].join(" ")}
                  href={this.props.webshopPrivacyLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Datenschutzerklärung
                </a>
              ) : null}
              {this.props.webshopCompanyInfoLink ? (
                <a
                  className={[
                    styles.termsAndConditionsLink,
                    "colorBasedOnPrimary",
                  ].join(" ")}
                  href={this.props.webshopCompanyInfoLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Impressum
                </a>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
