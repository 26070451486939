import React from "react";
import "./layout.scss";
import Header from "./header";
import Footer from "./footer";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";

class Layout extends React.Component {
  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content={this.props.metaDescription} />
          <title>{this.props.title}</title>
          <link
            rel="icon"
            type="image/png"
            href={this.props.favicon}
            sizes="16x16"
          />
          {this.props.headers
            ? this.props.headers.map(header => parse(`${header}`))
            : null}
        </Helmet>

        <Header
          webshopName={this.props.webshopName}
          logoUrl={this.props.logoUrl}
          buyerGroupId={this.props.buyerGroupId}
          webshopLogo={this.props.webshopLogo}
        />
        <div className="pageContainer">{this.props.children}</div>

        <Footer
          webshopfooterName={this.props.webshopfooterName}
          webshopAddress={this.props.webshopAddress}
          webshopZipCode={this.props.webshopZipCode}
          webshopOpeningHours={this.props.webshopOpeningHours}
          webshopCity={this.props.webshopCity}
          logoUrl={this.props.logoUrl}
          webshopTermsLink={this.props.footerLink}
          webshopPrivacyLink={this.props.webshopPrivacyLink}
          webshopCompanyInfoLink={this.props.webshopCompanyInfoLink}
          webshopName={this.props.webshopName}
        />
        {this.props.footers
          ? this.props.footers.map(footer => parse(`${footer}`))
          : null}
      </div>
    );
  }
}

export default Layout;
